/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Label, Form, FormFeedback, Spinner } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewAdvertiser as onADDAdvertiser,
  updateAdvertiser as onUpdateAdvertiser,
  readAdvertiser as onReadAdvertiser,
  addAdvertiserSuccess,
  addAdvertiserFail,
  updateAdvertiserSuccess,
  updateAdvertiserFail,
} from "store/Demand/Advertiser/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";
import { Select, SelectItem } from "@nextui-org/react";
import { Input, Spacer } from "@nextui-org/react";

const Create_Advertiser = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [advertiser, setAdvertiser] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [loader, setLoader] = useState(false);
  const animals = [
    { key: "cat", label: "Cat" },
    { key: "dog", label: "Dog" },
    { key: "elephant", label: "Elephant" },
    { key: "lion", label: "Lion" },
    { key: "tiger", label: "Tiger" },
    { key: "giraffe", label: "Giraffe" },
    { key: "dolphin", label: "Dolphin" },
    { key: "penguin", label: "Penguin" },
    { key: "zebra", label: "Zebra" },
    { key: "shark", label: "Shark" },
    { key: "whale", label: "Whale" },
    { key: "otter", label: "Otter" },
    { key: "crocodile", label: "Crocodile" }
  ];
  // console.log(props)

  useEffect(() => {
    if (props.selectedAdvertiser !== '') {
      setIsEdit(true)
      setAdvertiser(props.selectedAdvertiser)
    }
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      advertiserName: (advertiser && advertiser.advertiserName) || '',
    },
    validationSchema: Yup.object({
      advertiserName: Yup.string().required("Advertiser Name is required"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      if (props.selectedAdvertiser !== '') {
        dispatch(onUpdateAdvertiser({ ...values, advertiserID: props.selectedAdvertiser.advertiserID, advertiserName: values.advertiserName.trim(), userRole: userInfo.userRole, userID: userInfo.userID }));
      } else {
        dispatch(onADDAdvertiser({ ...values, advertiserName: values.advertiserName.trim(), userRole: userInfo.userRole, userID: userInfo.userID }));
      }
    }
  });

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.advertisers.errorMsg,
    successMsg: state.advertisers.successMsg,
  }));

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addAdvertiserSuccess(""));
        dispatch(addAdvertiserFail(""));
        dispatch(updateAdvertiserSuccess(""));
        dispatch(updateAdvertiserFail(""));
        setLoader(false)
        if (nextTab) {
          props.closeCanvas('')
          nextRef.current.click()
        } else {
          if (props.selectedAdvertiser !== '') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addAdvertiserFail(""));
        dispatch(updateAdvertiserFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addAdvertiserFail(""));
    dispatch(updateAdvertiserFail(""));
    props.closeCanvas('')
  };

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row style={{ padding: '3%' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Advertiser Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                {/* <Input
                  name="advertiserName"
                  type="text"
                  maxLength={65}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  value={validation.values.advertiserName || ""}
                  invalid={
                    validation.touched.advertiserName && validation.errors.advertiserName ? true : false
                  }
                  readOnly={props.selectedArchived}
                /> */}
                {validation.touched.advertiserName && validation.errors.advertiserName ? (
                  <FormFeedback type="invalid">{validation.errors.advertiserName}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Select
                  items={animals}
                  label="Favorite Animal"
                  placeholder="Select an animal"
                  className="max-w-xs"
                >
                  {(animal) => <SelectItem>{animal.label}</SelectItem>}
                </Select>
              </div>
              <Input clearable bordered labelPlaceholder="Name" initialValue="NextUI" />
      <Spacer y={2.5} />
      <Input
        clearable
        underlined
        labelPlaceholder="Name"
        initialValue="NextUI"
      />
      <Spacer y={1.5} />
      <Input clearable label="Name" placeholder="Name" initialValue="NextUI" />

              {/* <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
  <div className="absolute inset-0  bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>
  <div className="relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
    <div className="mx-auto max-w-md">
      <div className="divide-y divide-gray-300/50">
        <div className="space-y-6 py-8 text-base leading-7 text-gray-600">
          <p>An advanced online playground for Tailwind CSS, including support for things like:</p>
          <ul className="space-y-4">
            <li className="flex items-center">
              <svg className="h-6 w-6 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="11" />
                <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
              </svg>
              <p className="ml-4">
                Customizing your
                <code className="text-sm font-bold text-gray-900">tailwind.config.js</code> file
              </p>
            </li>
            <li className="flex items-center">
              <svg className="h-6 w-6 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="11" />
                <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
              </svg>
              <p className="ml-4">
                Extracting classes with
                <code className="text-sm font-bold text-gray-900">@apply</code>
              </p>
            </li>
            <li className="flex items-center">
              <svg className="h-6 w-6 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="11" />
                <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
              </svg>
              <p className="ml-4">Code completion with instant preview</p>
            </li>
          </ul>
          <p>Perfect for learning how the framework works, prototyping a new idea, or creating a demo to share online.</p>
        </div>
        <div className="pt-8 text-base font-semibold leading-7">
          <p className="text-gray-900">Want to dig deeper into Tailwind?</p>
          <p>
            <a href="https://tailwindcss.com/docs" className="text-sky-500 hover:text-sky-600">Read the docs &rarr;</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div> */}
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                    onClick={() => setNextTab(true)}
                  >
                    Save & Add IO Group
                  </button>
                }

                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {nextTab && successMsg &&
                  <Link ref={nextRef} to={`/Insertion_Order_Group?advertiserId=${successMsg.data.advertiserID}&advertiserName=${successMsg.data.advertiserName}&status=IOGroupCreateByAdvertiser`}  > </Link>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }

    </React.Fragment>
  );
};

export default withRouter(Create_Advertiser);

Create_Advertiser.propTypes = {
  history: PropTypes.object,
};