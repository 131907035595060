import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from 'react';
import { Switch, useHistory, BrowserRouter as Router, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";
import packageJson from "../package.json";
import { getBuildDate } from "./utils/utils.js";
import withClearCache from "./ClearCache";
import axios from "axios";
import { NextUIProvider } from '@nextui-org/react';
const ClearCacheComponent = withClearCache(MainApp);



// function App() {
//   const [isFollowed, setIsFollowed] = React.useState(false);

//   return (
//     <NextUIProvider >
//       <div className="purple-dark text-foreground bg-background">
//         <h1>NextUI with Custom Theme</h1>
//         <div className="flex gap-4">
//           <Button color="primary" variant="solid">Solid</Button>
//           <Button color="primary" variant="ghost">Ghost</Button>
//         </div>
//         <div class="dark dark:bg-gray-800 dark:text-white bg-white text-black">
//           <div>Text color changes based on theme</div>
//         </div>

//         <div class="light light:bg-gray-100 light:text-black bg-black text-white">
//           <div>Text color changes based on theme</div>
//         </div>
//         <div className="flex flex-wrap gap-4 items-center">
//           <Button color="primary" variant="solid">
//             Solid
//           </Button>
//           <Button color="primary" variant="faded">
//             Faded
//           </Button>
//           <Button color="primary" variant="bordered">
//             Bordered
//           </Button>
//           <Button color="primary" variant="light">
//             Light
//           </Button>
//           <Button color="primary" variant="flat">
//             Flat
//           </Button>
//           <Button color="primary" variant="ghost">
//             Ghost
//           </Button>
//           <Button color="primary" variant="shadow">
//             Shadow
//           </Button>
//         </div>
//         <Button
//       disableRipple
//       className="relative overflow-visible rounded-full hover:-translate-y-1 px-12 shadow-xl bg-background/30 after:content-[''] after:absolute after:rounded-full after:inset-0 after:bg-background/40 after:z-[-1] after:transition after:!duration-500 hover:after:scale-150 hover:after:opacity-0"
//       size="lg"
//     >
//       Press me
//     </Button>

//     <MyButton color="olive" size="md">
//       Press Me
//     </MyButton>


//     <Card className="max-w-[340px]">
//       <CardHeader className="justify-between">
//         <div className="flex gap-5">
//           <Avatar isBordered radius="full" size="md" src="https://nextui.org/avatars/avatar-1.png" />
//           <div className="flex flex-col gap-1 items-start justify-center">
//             <h4 className="text-small font-semibold leading-none text-default-600">Zoey Lang</h4>
//             <h5 className="text-small tracking-tight text-default-400">@zoeylang</h5>
//           </div>
//         </div>
//         <Button
//           className={isFollowed ? "bg-transparent text-foreground border-default-200" : ""}
//           color="primary"
//           radius="full"
//           size="sm"
//           variant={isFollowed ? "bordered" : "solid"}
//           onPress={() => setIsFollowed(!isFollowed)}
//         >
//           {isFollowed ? "Unfollow" : "Follow"}
//         </Button>
//       </CardHeader>
//       <CardBody className="px-3 py-0 text-small text-default-400">
//         <p>
//           Frontend developer and UI/UX enthusiast. Join me on this coding adventure!
//         </p>
//         <span className="pt-2">
//           #FrontendWithZoey 
//           <span className="py-2" aria-label="computer" role="img">
//             💻
//           </span>
//         </span>
//       </CardBody>
//       <CardFooter className="gap-3">
//         <div className="flex gap-1">
//           <p className="font-semibold text-default-400 text-small">4</p>
//           <p className=" text-default-400 text-small">Following</p>
//         </div>
//         <div className="flex gap-1">
//           <p className="font-semibold text-default-400 text-small">97.1K</p>
//           <p className="text-default-400 text-small">Followers</p>
//         </div>
//       </CardFooter>
//     </Card>
//       </div>
//     </NextUIProvider>
//   );
// }

// export default App;

function App(props) {


  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const path = window.location
  console.log(path.hostname, userInfo)
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();


  return (
    <React.Fragment>
      <NextUIProvider>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
            {userInfo ? <Redirect to="/Dashboard" /> : <Redirect to="/login" />}
          </Switch>
        </Router>
      </NextUIProvider>
    </React.Fragment>
  );
}

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

function MainApp(props) {
  return (
    <div>
      <ClearCacheComponent />
      {React.createElement(App(props))}
    </div>
  );
}

export default connect(mapStateToProps, null)(App);
