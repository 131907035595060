import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';
import axios from "axios";

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getIOGroups as onGetIOGroups,
  statusIOGroup as onStatusIOGroup,
  archiveIOGroup as onArchiveIOGroup,
  statusIOGroupSuccess,
  archiveIOGroupSuccess,
  addIOGroupFail,
  updateIOGroupFail,
} from "store/Demand/IOGroup/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_IO_Group from "./Create_IO_Group";
import "./IOG.scss"
import LoadingTable from "pages/LoaderPages/LoadingTable";
import LineItemDataTable from "Product_Helpers/ProductDataTable"
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const Insertion_Order_Group = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [statusSearch, setStatusSearch] = useState('Active')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [createIOG, setCreateIOG] = useState(true)

  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  const Headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
    }
  }

  useEffect(() => {
    dispatch(onGetIOGroups(null));
    totalRecords = 0
    if (parsed.status === 'IOGroupCreateByAdvertiser') {
      setIsRight(true);
    }
  }, []);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.iOGroupID)
        selectedItem.push(row.iOGroupID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.iOGroupID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.iOGroupID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.iOGroupID)
          selectedItem.push(item.iOGroupID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  var { iOGroups,paginationData } = useSelector(state => ({
    iOGroups: state.iOGroups.iOGroups,
    paginationData: state.iOGroups.pagination
  }));

  totalRecords = paginationData ? paginationData.total_records ? paginationData.total_records : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.iOGroups.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.iOGroups.noData,
  }));

  const { iOGroupsResponse } = useSelector(state => ({
    iOGroupsResponse: state.iOGroups.response,
  }));

  const { archiveIOGroupsResponse } = useSelector(state => ({
    archiveIOGroupsResponse: state.iOGroups.archiveMsg,
  }));

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  console.log(iOGroupsResponse)
  useEffect(() => {
    if (iOGroupsResponse !== null) {
      if (iOGroupsResponse.success) {
        toastr.success('IOGroup Status Changed Successfully')
        if (parsed.advertiserId) {
          dispatch(onGetIOGroups({ advertiserID: parsed.advertiserId, userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
        } else {
          dispatch(onGetIOGroups({ advertiserID: '', userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusIOGroupSuccess(null));
      }
    }
  }, [dispatch, iOGroupsResponse]);

  useEffect(() => {
    if (archiveIOGroupsResponse !== null) {
      if (archiveIOGroupsResponse.success) {
        toastr.success('IOGroup Archived Successfully')
        if (parsed.advertiserId) {
          dispatch(onGetIOGroups({ advertiserID: parsed.advertiserId, userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
        } else {
          dispatch(onGetIOGroups({ advertiserID: '', userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(archiveIOGroupSuccess(null));
      }
    }
  }, [dispatch, archiveIOGroupsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    if (parsed.advertiserId) {
      dispatch(onGetIOGroups({ advertiserID: parsed.advertiserId, userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
    } else {
      dispatch(onGetIOGroups({ advertiserID: '', userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
    }
    dispatch(addIOGroupFail(""));
    dispatch(updateIOGroupFail(""));
    setIsRight(!isRight);
    setCreateIOG(!createIOG)
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, iOGroup) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('IOGroup Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('IOGroup Updated Successfully!...')
    }
    selectedData = iOGroup
    setIsArchived(iOGroup.isIOGroupArchived)
    if (parsed.advertiserId) {
      dispatch(onGetIOGroups({ advertiserID: parsed.advertiserId, userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
    } else {
      dispatch(onGetIOGroups({ advertiserID: '', userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
    }
    dispatch(addIOGroupFail(""));
    dispatch(updateIOGroupFail(""));
    setIsRight(close);
    setCreateIOG(!createIOG)
  };

  useEffect(() => {
    console.log(iOGroups)
    if (parsed.advertiserId) {
      dispatch(onGetIOGroups({ advertiserID: parsed.advertiserId, userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
    }
  }, []);

  useEffect(() => {
    if (iOGroups !== null && !parsed.advertiserId) {
      dispatch(onGetIOGroups({ advertiserID: '', userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const iOGroupListColumns = [
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "iOGroupID",
      sort: true,
      formatter: (cellContent, iOGroup) => (
        <>
          <span>{iOGroup.iOGroupID}</span>
        </>
      ),
    },
    {
      dataField: "iOGroupCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "IOG Name",
      dataField: "iOGroupName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, iOGroup) => (
        <>
          <h5 className="font-size-12 mb-1">
          { (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 
            <Link to={`/Insertion_Order?iOGroupID=${iOGroup.iOGroupID}&iOGroupName=${iOGroup.iOGroupName}&status=IOCreateByParent`} className="color-dark-blue ">
              {iOGroup.iOGroupName}
            </Link> : (UserRolePrivileges && UserRolePrivileges.Demand && UserRolePrivileges.Demand.isIOsAccess) ? 
              <Link to={`/Insertion_Order?iOGroupID=${iOGroup.iOGroupID}&iOGroupName=${iOGroup.iOGroupName}&status=IOCreateByParent`} className="color-dark-blue ">
              {iOGroup.iOGroupName}
            </Link> : iOGroup.iOGroupName}
          </h5>
        </>
      ),
    },
    {
      dataField: "iOGroupStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, iOGroup) => (
        <>
          <span className={iOGroup.newStatus === 'Active' ? "active-state" : iOGroup.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{iOGroup.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOGroups) && (!UserRolePrivileges.Demand.IOGroups.update),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, iOGroup) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className="text-success " onClick={toggleDrawer(true, iOGroup)}>
            {iOGroup.newStatus === 'Archived' &&
              <i
                className="mdi mdi-eye font-size-18"
                id="edittooltip"
              ></i>
            }
            {iOGroup.newStatus !== 'Archived' &&
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
              ></i>
            }
          </span>
        </div>
      ),
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );

  const { SearchBar } = Search;
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveIOGroup = async () => {
    dispatch(onArchiveIOGroup({ idList: selectedItem,userRole:userInfo.userRole,userID:userInfo.userID  }));
  }

  const statusIOGroup = (state) => (event) => {
    console.log(state)
    dispatch(onStatusIOGroup({ idList: selectedItem, iOGroupStatus: state,userRole:userInfo.userRole,userID:userInfo.userID  }));
  }

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    if (parsed.advertiserId) {
      dispatch(onGetIOGroups({ advertiserID: parsed.advertiserId, userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
    } else {
      dispatch(onGetIOGroups({ advertiserID: '', userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, type: '' }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    if (parsed.advertiserId) {
      dispatch(onGetIOGroups({ advertiserID: parsed.advertiserId, userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active', type: '' }));
    } else {
      dispatch(onGetIOGroups({ advertiserID: '', userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active', type: '' }));
    }
  }

  console.log(iOGroups)

  if (iOGroups.length > 0) {
    if (UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOGroups) {
      if (!UserRolePrivileges.Demand.IOGroups.archive && !UserRolePrivileges.Demand.IOGroups.status) {
        iOGroups.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        iOGroups.map((item, index) => {
          if (item.isIOGroupArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
    if (parsed.advertiserId) {
      dispatch(onGetIOGroups({ advertiserID: parsed.advertiserId, userRole:userInfo.userRole,userID:userInfo.userID,pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText,filter: 'Active', type: '' }));
    } else {
      dispatch(onGetIOGroups({ advertiserID: '', userRole:userInfo.userRole,userID:userInfo.userID,pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText,filter: 'Active', type: '' }));
    }
  }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>Insertion Order Group | Bidsxchange</title>
        </Helmet>
        <Container fluid={true}>
          {/* Render Breadcrumbs */}

          <div className=" d-sm-flex  justify-content-between">
            {parsed.advertiserName ? (
              <Breadcrumbs title="Demand" breadcrumbItem="Insertion Order Group" advertiserName={parsed.advertiserName} />
            ) : (
              <Breadcrumbs title="Demand" breadcrumbItem="Insertion Order Group" breadList="All IOGs" />
            )
            }
            {UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOGroups && UserRolePrivileges.Demand.IOGroups.create &&
              <div className="text-sm-end">
                <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={toggleRightCanvas}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Add IO Group
                </Button>
              </div>
            }
          </div>
          <ProductDataTableLI statusAccess={(UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOGroups && UserRolePrivileges.Demand.IOGroups.status) ? true : false}
            archiveAccess={(UserRolePrivileges.Demand && UserRolePrivileges.Demand.IOGroups && UserRolePrivileges.Demand.IOGroups.archive) ? true : false} loading={loading} data={iOGroups} columns={iOGroupListColumns} statusChange={statusIOGroup} archiveLineItem={archiveIOGroup} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Insertion Order Group' : 'Add Insertion Order Group'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_IO_Group closeCanvas={toggleDrawer(false, '')} selectedArchived={isArchived} selectedIOGroup={selectedData} selectedAdvertiser={(parsed.status === 'IOGroupCreateByAdvertiser' || parsed.status === 'IOGroupCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

export default Insertion_Order_Group
