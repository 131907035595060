/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Row, Col, Input, Label, Form, FormFeedback,
  FormGroup,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import Select from "react-select";
// Formik Validation
import moment from "moment";
import axios from "axios";
import queryString from 'query-string';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { Link } from "react-router-dom";
import classnames from "classnames"

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import {
  addNewPlayerSetting as onADDPlayerSetting,
  updatePlayerSetting as onUpdatePlayerSetting,
  readPlayerSetting as onReadPlayerSetting,
  addPlayerSettingSuccess,
  addPlayerSettingFail,
  updatePlayerSettingSuccess,
  updatePlayerSettingFail,
} from "store/Supply/PlayerSetting/actions";

import {
  getPartners as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getPublisher as onGetPublishers,
} from "store/Supply/Publisher/actions";

import {
  getAdUnitGroup as onGetAdUnitGroups,
} from "store/Supply/AdUnitGroup/actions";

import {
  getAdUnit as onGetAdUnits,
} from "store/Supply/AdUnit/actions";

import {
  getCommonDevices as onGetAllContextual,
} from "store/CommonEvents/actions";

import PlayerContent from './PlayerContent/PlayerContent';
import PlayerControls from './PlayerControls/PlayerControls'
import PlayerGesture from './PlayerGesture/PlayerGesture';
import PlayerAdConfig from './PlayerAdConfig/PlayerAdConfig';
import Toaster from "pages/Toaster/Toaster";
import PlayerGeneralSettings from "./PlayerGeneralSettings";
import GeoPlayer from "./GeoPlayer";
import GeoPlayerSettings from "./GeoPlayerSettings";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var selectedAdUnits = []
var selectedlength = 0

const Create_Player_Settings = props => {
  const dispatch = useDispatch();
  const [nextTab, setNextTab] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [playerSetting, setPlayerSetting] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [adUnitData, setAdUnitData] = useState([])
  const [submitted, setSubmitted] = useState(false);

  const [activeTab, setactiveTab] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  console.log(props)

  const [contextualData, setContextualData] = useState({})
  const { contextuals } = useSelector(state => ({
    contextuals: state.commonEvents.contextualAllData,
  }));

  const parsed = queryString.parse(window.location.search);

  const [finalData, setFinalData] = useState({
    playerSettingName: '',
    adUnitID: [],
    playerSettingsContent: {
      type: 'directUrl',
      list: []
    },
    playerSettingsAesthetics: {
      closeBtn_position: "outer-right",
      closeBtn_bottomMargin: 0,
      closeBtn_rightMargin: 0,
      closeBtn_topMargin: 0,
      closeBtn_leftMargin: 0,
      closeBtn_height: 12,
      closeBtn_width: 12,
      closeBtn_Text: '',
      closeBtn_InfoURL: 'https://bidsxchange.com/',
      showPlaylist: false,
      previousNextButton: true,
      playPauseButton: true,
      playOnViewPercentage: 50,
      fullscreenToggle: false,
      duration: true,
      soundButton: true,
      playbackRates: false,
      autoplay: false,
      autoContinue: true,
      loop: true,
      brandIdentity: false,
      playOnView: true,
      autoSkip: true,
      loadAdOnView: true,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      muted: true,
      isResponsive: true,
      playerSize: 'desktop',
      playerHeight: 360,
      playerWidth: 640,
      floatingCloseButton: true,
      floatOnStart: true,
      floatingOnlyOnAd: false,
      floatingMarginLeft: 5,
      floatingMarginRight: 5,
      floatingMarginTop: 5,
      floatingMarginBottom: 5,
      floatingPlacement: "bottom-right",
      floatingZindex: "99999",
      floatingSize: '',
      floatingSizeHeight: '120',
      floatingSizeWidth: '320',
      floatingSizeVH: '',
      floatingSizeVW: '',
      floatOnView: 50,
      playerState: 'inRead',
      stickyPosition: "bottom",
      stickyPlacement: 'right',
      contentIdentifier: 'tag',
      contentIdentifierName: '',
      customCss: '',
      backgroundColor: '',
      closeBtn: true,
      logo: true,
      logoPosition: 'right',
      logoHeight: 21,
      logoWidth: 21,
      logoURL: "https://admin.bidsxchange.com/images/shards-dashboard.comm",
      logoLpu: '',
      logoClickThroughURL: "https://streamlyn.com/products-seller",
      logoToolTip: '',
      logoTextFontSize: 14,
      logoText: "Ads by Bidsxchange",

      playerLoadOnPageView: true,
      checkTabInView: true,
      playAdInView: true,
      skipButton: false,
      skipTimer: 25,
      skipText: 'skip',
      timeToSkip: 25,
      // showAutoSkipButtons: '',
      autoSkipText: 'stay',
      dfp1x1: false
    },
    playerSettingsConfig: {
      adsState: '',
      breakingAds: 15,
      maxAdsPerSlot: 0,
      adBreak: 0,
      midRollSlots: [],
      preRollSlots: {},
      vastRerun: 0,
      faultCheck: 0,
      vastTag: '',
      preRollState: false,
      preRollVastTag: '',
      midRollVastTag: ''
    },
    playerSettingsType: 'inStream',
    devicesIn: [],
    devicesEx: [],
    countriesIn: [],
    countriesEx: [],
    citiesIn: [],
    citiesEx: [],
    urlIn: [],
    urlEx: [],
    playerType: 'preRoll'
  })

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  const [playerData, setPlayerData] = useState({
    playerSettingsAesthetics: {},
    playerSettingsConfig: {},
  })

  useEffect(() => {
    if (contextuals !== null) {
      dispatch(onGetAllContextual());
    }
  }, []);

  useEffect(() => {
    if (contextuals && JSON.stringify(contextuals) !== "{}") {
      setContextualData(contextuals)
    }
  }, [contextuals]);


  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  useEffect(() => {
    if (props.selectedPlayerSetting !== '') {
      setIsEdit(true)
      setPlayerSetting(props.selectedPlayerSetting)
      setFinalData(props.selectedPlayerSetting)
      setAdUnitDataTree(props.selectedPlayerSetting.playerType)
    } else {
      setAdUnitDataTree('preRoll')
    }
  }, []);

  const [newSettings, setNewSettings] = useState(true)
  const linkRef = useRef();

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
  }

  const [targetStates, setTargetStates] = useState({
    urls: false,
    countries: false,
    cities: false,
    devices: false,
  })

  const changePlayerType = e => {
    setFinalData(finalData => ({ ...finalData, playerType: e.target.value }))
    setAdUnitDataTree(e.target.value)
  }

  const getContentData = async (selectedData) => {
    console.log(selectedData)
    setFinalData(finalData => ({ ...finalData, playerSettingsContent: { ...finalData.playerSettingsContent, ...selectedData } }));
  }

  const getControlslData = async (selectedData) => {
    setPlayerData({ ...playerData, playerSettingsAesthetics: ({ ...playerData.playerSettingsAesthetics, ...selectedData }) })
    setFinalData({ ...finalData, playerSettingsAesthetics: ({ ...finalData.playerSettingsAesthetics, ...selectedData }) })
  }

  const getAdConfigData = async (selectedData) => {
    setPlayerData({ ...playerData, playerSettingsConfig: ({ ...playerData.playerSettingsConfig, ...selectedData }) })
    setFinalData({ ...finalData, playerSettingsConfig: ({ ...playerData.playerSettingsConfig, ...selectedData }) })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFinalData(finalData => ({ ...finalData, [name]: value }));
  }

  const getPlayerData = async (selectedPlayerData) => {
    console.log(selectedPlayerData)
    setFinalData(finalData => ({ ...finalData, ...selectedPlayerData }));
  }

  const getTargetingLineItemData = async (selectedPlayerSettingData) => {
    setTargetStates({ ...targetStates, ...selectedPlayerSettingData })
  }

  useEffect(async () => {
    if (parsed.settingsId) {

      await axios.post("/v2.0/supply/PlayerSettings/read/" + parsed.settingsId, body,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is ==>", res)
          if (res.data) {
            console.log(res.data)
            settingsDatas = { ...finalData, ...res.data.response }
            setFinalData({ ...finalData, ...res.data.response })
            setNewSettings(false)
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }

  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(playerData)

    if (finalData.playerSettingsContent.list.length === 0) {
      toastr.error("Please Add atleast one Video Content")
    } else {
      if (finalData.playerSettingsContent.list.length > 0) {
        finalData.playerSettingsContent.list.map((item, index) => {
          delete item.id
          delete item.selectManually
          delete item.selectUpload
        })
      }
      console.log("submit click", finalData)
      if (finalData.playerSettingName) {
        if (props.selectedPlayerSetting !== '') {
          dispatch(onUpdatePlayerSetting({ ...finalData, oldPlayerSettingsPlayList: props.selectedPlayerSetting.playerSettingsContent,userRole:userInfo.userRole,userID:userInfo.userID }))
        } else {
          dispatch(onADDPlayerSetting({ ...finalData,userRole:userInfo.userRole,userID:userInfo.userID }))
        }
      }
    }
  }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.playerSettings.errorMsg,
    successMsg: state.playerSettings.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addPlayerSettingSuccess(""));
        dispatch(addPlayerSettingFail(""));
        dispatch(updatePlayerSettingSuccess(""));
        dispatch(updatePlayerSettingFail(""));
        if (props.selectedPlayerSetting !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  const setAdUnitDataTree = (type) => {
    selectedAdUnits = []
    selectedlength = 0
    let trees = []
    let bodyAdUnit = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        playerType: type
    }
  axios.post("/v2.0/supply/PlayerSettings/getAdUnit", bodyAdUnit,
      {
        headers: headers
      })
      .then((res) => {
        console.log(res)
        if (res.data) {

          if (res.data.data.length > 0) {
            res.data.data.map((item, i) => {
              trees.push(createAdUnitsTree(item.AdUnitName, item.AdUnitID, item.child))
            })
          }
          console.log(trees)

          console.log(trees, trees.length, adUnitData)
          if (trees.length > 0) {
            if (props.selectedPlayerSetting !== '') {
              var selectedData1 = []
              if (props.selectedPlayerSetting.adUnitIDName.length > 0) {
                let adUnitIds = []
                props.selectedPlayerSetting.adUnitIDName.map((elem, index) => {
                  let obj1 = {}
                  obj1.value = elem.ID
                  obj1.label = elem.Name
                  obj1.isChild = elem.IsChildAdunit
                  selectedData1.push(obj1)
                  selectedAdUnits.push({ ID: elem.ID, isChild: elem.IsChildAdunit })
                  adUnitIds.push({ ID: elem.ID, isChild: elem.IsChildAdunit })
                  sbc(elem.ID, true, elem.ParentAdUnitID ? elem.ParentAdUnitID : '', trees)
                })
                // setSelectedAdUnitsData(Object.values(selectedData1.reduce((acc, cur) => Object.assign(acc, { [cur.value]: cur }), {})))
                console.log(adUnitIds)
                setFinalData(finalData => ({ ...finalData, adUnitID: adUnitIds }));
                setFinalData(finalData => ({ ...finalData, adUnitIDOld: adUnitIds }));

                // AdUnitSelecteds = props.selectedPlayerSetting.adUnitIDName.length > 1 ? props.selectedPlayerSetting.adUnitIDName.length + ' Ad Units are Selected' : '1 Ad Unit is Selected'
                setAdUnitsSelected(props.selectedPlayerSetting.adUnitIDName.length > 1 ? props.selectedPlayerSetting.adUnitIDName.length + ' Ad Units are Selected' : '1 Ad Unit is Selected')
              } else {
                setFinalData(finalData => ({ ...finalData, adUnitIDOld: [] }));
              }
            }
            else if (JSON.stringify(parsed) !== '{}') {
              if (parsed.status === 'PlayerSettingCreateByAdUnit') {
                selectedAdUnits.push({ ID: Number(parsed.adUnitID), isChild: false })
                setFinalData(finalData => ({ ...finalData, adUnitID: [{ ID: Number(parsed.adUnitID), isChild: false }] }));
                sbc(Number(parsed.adUnitID), true, '', trees)
                setAdUnitsSelected('1 Ad Unit is Selected')
              }
            }
            else {
              setAdUnitData(trees)
            }
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });

    let bodyHIAdUnit = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
    }
    axios.post("/v2.0/supply/HighImpactSettings/read/AdunitHighImpact", bodyHIAdUnit,
      {
        headers: headers
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          if (res.data.data.length > 0) {
            res.data.data.map((item, i) => {
              if (item.aUnitName.includes('VSRV')) {
                trees.push(createAdUnitsTree(item.aUnitName, item.adUnitID, item.child))
              }
            })
          }
          console.log(trees)
          console.log(trees, trees.length, adUnitData)
          if (trees.length > 0) {
            if (props.selectedPlayerSetting !== '') {
              if (props.selectedPlayerSetting.adUnitIDName.length > 0) {
                let adUnitIds = []
                props.selectedPlayerSetting.adUnitIDName.map((elem, index) => {
                  selectedAdUnits.push({ ID: elem.ID, isChild: elem.IsChildAdunit })
                  adUnitIds.push({ ID: elem.ID, isChild: elem.IsChildAdunit })
                  sbc(elem.ID, true, elem.parentID ? elem.parentID : '', trees)
                })
                setFinalData(finalData => ({ ...finalData, adUnitID: adUnitIds }));
                setFinalData(finalData => ({ ...finalData, oldAdUnitID: adUnitIds }));
                setAdUnitsSelected(props.selectedPlayerSetting.adUnitIDName.length > 1 ? props.selectedPlayerSetting.adUnitIDName.length + ' Ad Units are Selected' : '1 Ad Unit is Selected')
              } else {
                setFinalData(finalData => ({ ...finalData, oldAdUnitID: [] }));
              }
            }
            else {
              console.log(trees)
              let treeData = trees
              setAdUnitData([...treeData])
            }
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  };

  const createAdUnitsTree = (groupName, id, options) => {
    return {
      label: groupName,
      value: id,
      checked: false,
      ParentID: '',
      children: []
    };
  };

  const objectModalAdUnits = (response, parentId) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.childAdUnitID
      obj.label = item.childAdUnitName
      obj.ParentID = parentId
      obj.checked = false
      dataList.push(obj)
    })
    return dataList
  }


  const [adUnitsSelected, setAdUnitsSelected] = useState('Please Select Ad Units')

  const onChangeAdUnit = (currentNode, selectedNodes) => {

    console.log(currentNode, selectedNodes)
    let index = selectedAdUnits.findIndex(x => x.ID === currentNode.value)
    if (currentNode.checked) {
      if (index === -1) {
        selectedAdUnits.push({
          ID: currentNode.value,
          isChild: currentNode._parent ? true : false
        })
      }
    } else {
      selectedAdUnits.splice(index, 1)
    }
    let array = [...adUnitData]
    selectedlength = selectedAdUnits.length
    console.log(selectedAdUnits, selectedlength)
    let AdUnitSelecteds = selectedlength > 1 ? selectedlength + ' Ad Units are Selected' : selectedlength === 1 ? '1 Ad Unit is Selected' : 'Please Select Ad Units'
    setAdUnitsSelected(AdUnitSelecteds)
    sbc(currentNode.value, currentNode.checked, currentNode.ParentID, array)
  }

  const sbc = (selectedId, checked, parentId, array) => {
    if (array.length > 0) {
      if (parentId) {
        let parentIndex = array.findIndex(x => x.value === parentId)
        let childIndex = array[parentIndex].children.findIndex(x => x.value === selectedId)
        array[parentIndex].children[childIndex].checked = checked
        let selectedElementParent = array[parentIndex]
        array.splice(parentIndex, 1)
        array = [selectedElementParent, ...array]
      } else {
        let index = array.findIndex(x => x.value === selectedId)
        array[index].checked = checked
        let selectedElement = array[index]
        array.splice(index, 1)
        array = [selectedElement, ...array]
      }
      setAdUnitData(array)
      setFinalData(finalData => ({ ...finalData, adUnitID: selectedAdUnits }));
    }
  }

  return (
    <React.Fragment>
      <div className="col-12">
        <Form onSubmit={handleSubmit}>
          <div className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <div className="col-10">
              <Row >
                <Col xs={6}>
                  <div className="mb-3 HIS">
                    <Label> Ad Units</Label>
                    <DropdownTreeSelect data={adUnitData} texts={{ placeholder: adUnitsSelected }} clearSearchOnChange={true} inlineSearchInput={true} keepOpenOnSelect={true} onChange={onChangeAdUnit} mode={'hierarchical'} />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Player Setting Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                    <Input
                      name="playerSettingName"
                      type="text"
                      onChange={handleChange}
                      value={finalData.playerSettingName || ""}
                      className={'form-control' + (submitted && !finalData.playerSettingName ? ' is-invalid' : '')}
                    />
                    {submitted && !finalData.playerSettingName ? (
                      <FormFeedback type="invalid">{'Player Setting Name is Required'}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <Row >
              <Col lg="12" className="p-0">
                <Card>
                  <CardBody className="p-0">
                    <h4 className="card-title m-3">Customization </h4>
                    <div className=" wizard clearfix vertical">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                            // disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">01</span> Content Personalization
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                            // disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number ">02</span> Player Config
                            </NavLink>
                          </NavItem>
                          {/* <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                            // disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">03</span> Player Gesture
                            </NavLink>
                          </NavItem> */}
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                            // disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">03</span> Player Appearance
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 4 })}
                              onClick={() => {
                                setactiveTab(4)
                              }}
                            // disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">04</span> Targeting
                            </NavLink>
                          </NavItem>
                          {/* <NavItem
                            className={classnames({ current: activeTab === 6 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 6 })}
                              onClick={() => {
                                setactiveTab(6)
                              }}
                            >
                              <span className="number">06</span> Geo Targeting
                            </NavLink>
                          </NavItem> */}
                          {/* <NavItem
                            className={classnames({ current: activeTab === 6 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 6 })}
                              onClick={() => {
                                setactiveTab(6)
                              }}
                            // disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">06</span> Player Tag
                            </NavLink>
                          </NavItem> */}
                        </ul>
                      </div>
                      <div className="content clearfix ">
                        <TabContent activeTab={activeTab} className="body">

                          <TabPane tabId={1}>
                            <div>
                              <PlayerContent selectedItems={getContentData} settingsId={parsed.settingsId} settingsData={finalData} selectedPlayer={props.selectedPlayerSetting} />
                            </div>
                          </TabPane>
                          <TabPane tabId={2}>
                            <PlayerAdConfig selectedItems={getAdConfigData} settingsId={parsed.settingsId} settingsData={finalData} selectedPlayer={props.selectedPlayerSetting} />
                          </TabPane>
                          {/* <TabPane tabId={3}>
                            <div>
                              <PlayerGesture selectedItems={getGestureData} settingsId={parsed.settingsId} settingsData={finalData} selectedPlayer={props.selectedPlayerSetting} />
                            </div>
                          </TabPane> */}
                          <TabPane tabId={3}>
                            <div>
                              <PlayerControls selectedItems={getControlslData} settingsId={parsed.settingsId} settingsData={finalData} selectedPlayer={props.selectedPlayerSetting} />
                            </div>
                          </TabPane>
                          <TabPane tabId={4}>
                            <div>
                              <GeoPlayerSettings selectedItems={getPlayerData} selectedPlayer={props.selectedPlayerSetting} contextualData={contextualData} targetedSelectedItems={getTargetingLineItemData} settingsId={parsed.settingsId} settingsData={finalData} tabId={activeTab} />
                            </div>
                          </TabPane>
                          {/* <TabPane tabId={6}>
                            <GeoPlayer selectedItems={getPlayerData} selectedPlayer='' settingsId={parsed.settingsId} settingsData={finalData} tabId={activeTab} />
                          </TabPane> */}
                          {/* <TabPane tabId={6}>
                            <div className="player-content">
                              <div className="container-fluid">

                                <div className="form-group">
                                  <h4 className="heading3">Player Tag</h4>
                                  <Input type="textarea" id="textarea"
                                    rows="3" style={{ width: '100%' }}
                                    name='bannerAdTag'
                                    className={'form-control mb-4 mt-3'}
                                  />
                                </div>
                              </div>
                            </div>
                          </TabPane> */}
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li
                            className={activeTab === 6 ? "next disabled" : "next"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>}
                &nbsp;&nbsp;
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }

              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' &&
          <Toaster status="error" msg={errorMsg} />
        }
      </div>
    </React.Fragment>
  )
}

Create_Player_Settings.propTypes = {}

export default Create_Player_Settings