import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGOUT_USER, SOCIAL_LOGIN, LOGIN_USERs } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess, loginSuccesss } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
  postLogin
} from "../../../helpers/Backend_helper";

//Import axios *Prash*
import axios from "axios";

var Headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
}

function* loginUsers({ payload: { user, history, state } }) {
  try {
    console.log(localStorage.getItem('previousNavigation'))
    const previousURL = localStorage.getItem('previousNavigation');
    console.log(state)
    // const response = yield call(postLogin, {
    //   username: user.username,
    //   password: user.password,
    // });
    const response = {
      "notification": [
        {
          "notificationMessage": "Reports for the date of August 9th will be delayed; it will be resolved by the end of the day.",
          "isRead": false,
          "notificationID": 468,
          "notificationCreateTime": "2023-08-10 06:59:19",
          "userID": 242
        },
        {
          "notificationMessage": "Reports for the date of 1st September will be delayed; will keep you posted once the reports are updated.\n",
          "isRead": false,
          "notificationID": 484,
          "notificationCreateTime": "2023-09-02 02:02:38",
          "userID": 242
        },
        {
          "notificationMessage": "Display Ad format Report for the date of 14th September will be delayed.",
          "isRead": false,
          "notificationID": 492,
          "notificationCreateTime": "2023-09-16 03:35:39",
          "userID": 242
        },
        {
          "notificationMessage": "MessageCube Corp INC Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 642,
          "notificationCreateTime": "2024-08-05 02:32:27",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 644,
          "notificationCreateTime": "2024-08-05 03:13:22",
          "userID": 242
        },
        {
          "notificationMessage": "testing_12 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 645,
          "notificationCreateTime": "2024-08-05 03:17:36",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 646,
          "notificationCreateTime": "2024-08-05 03:35:26",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 647,
          "notificationCreateTime": "2024-08-05 03:47:42",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 648,
          "notificationCreateTime": "2024-08-05 03:49:27",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 649,
          "notificationCreateTime": "2024-08-05 05:20:47",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 650,
          "notificationCreateTime": "2024-08-05 05:22:10",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 651,
          "notificationCreateTime": "2024-08-05 05:24:42",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 652,
          "notificationCreateTime": "2024-08-05 05:26:21",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 653,
          "notificationCreateTime": "2024-08-05 05:29:34",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 654,
          "notificationCreateTime": "2024-08-05 05:33:02",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 655,
          "notificationCreateTime": "2024-08-05 05:36:33",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 656,
          "notificationCreateTime": "2024-08-05 05:51:11",
          "userID": 242
        },
        {
          "notificationMessage": "Test_Partner_34 Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 657,
          "notificationCreateTime": "2024-08-05 05:54:02",
          "userID": 242
        },
        {
          "notificationMessage": "<p>May I follow up on July invoice?</p>",
          "isRead": false,
          "notificationID": 658,
          "notificationCreateTime": "2024-08-12 23:09:51",
          "userID": 242
        },
        {
          "notificationMessage": "Siam Sport Digital Media Co. Ltd. Requested   the  Video Stories adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 660,
          "notificationCreateTime": "2024-08-16 02:28:02",
          "userID": 242
        },
        {
          "notificationMessage": "received the invoice with thanks!",
          "isRead": false,
          "notificationID": 661,
          "notificationCreateTime": "2024-08-16 02:57:24",
          "userID": 242
        },
        {
          "notificationMessage": "Arab Times Requested   the  Contextual Video adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 662,
          "notificationCreateTime": "2024-08-17 03:29:54",
          "userID": 242
        },
        {
          "notificationMessage": "Arab Times Requested   the  Ad Push Down adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 663,
          "notificationCreateTime": "2024-08-17 03:30:05",
          "userID": 242
        },
        {
          "notificationMessage": "Arab Times Requested   the  In-Content adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 664,
          "notificationCreateTime": "2024-08-17 03:30:19",
          "userID": 242
        },
        {
          "notificationMessage": "<p>Hi,</p><p><br></p><p>As I sent a notice to Streamlyn team via email about contract termination, and kindly request to deactivate our account with Streamlyn, effective immediately.</p><p>However, there has no any response or further action from Dipika or Streamlyn yet. Please check and proceed upon our request accordingly.</p><p><br></p><p>In term of Financial, we have Fakkiresh and finance team to take care of it.</p><p><br></p><p>Thank you.</p><p><br></p>",
          "isRead": false,
          "notificationID": 665,
          "notificationCreateTime": "2024-08-19 23:45:55",
          "userID": 242
        },
        {
          "notificationMessage": "",
          "isRead": false,
          "notificationID": 666,
          "notificationCreateTime": "2024-08-19 23:46:17",
          "userID": 242
        },
        {
          "notificationMessage": "<p>hello</p>",
          "isRead": false,
          "notificationID": 667,
          "notificationCreateTime": "2024-08-21 06:46:11",
          "userID": 242
        },
        {
          "notificationMessage": "Ekivi Technology (Hong Kong) Limited Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 668,
          "notificationCreateTime": "2024-08-29 05:53:08",
          "userID": 242
        },
        {
          "notificationMessage": "AVATARMOBI TECHNOLOGY LIMITED Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 670,
          "notificationCreateTime": "2024-08-29 08:08:16",
          "userID": 242
        },
        {
          "notificationMessage": "Ad Space Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 672,
          "notificationCreateTime": "2024-09-03 06:49:01",
          "userID": 242
        },
        {
          "notificationMessage": "POMPEI MEDIA Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 674,
          "notificationCreateTime": "2024-09-04 10:03:38",
          "userID": 242
        },
        {
          "notificationMessage": "NEWBYERA TECHNOLOGY LIMITED Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 676,
          "notificationCreateTime": "2024-09-10 02:24:52",
          "userID": 242
        },
        {
          "notificationMessage": "Asking Media Limited Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 678,
          "notificationCreateTime": "2024-09-12 05:58:35",
          "userID": 242
        },
        {
          "notificationMessage": "NAPTECH LABS LTD Requested   the  Ad Push Down adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 680,
          "notificationCreateTime": "2024-09-12 08:11:02",
          "userID": 242
        },
        {
          "notificationMessage": "NAPTECH LABS LTD Requested   the  Slider Ads adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 681,
          "notificationCreateTime": "2024-09-12 08:11:10",
          "userID": 242
        },
        {
          "notificationMessage": "NAPTECH LABS LTD Requested   the  Tower Ads adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 682,
          "notificationCreateTime": "2024-09-12 08:11:18",
          "userID": 242
        },
        {
          "notificationMessage": "NAPTECH LABS LTD Requested   the  Stay On adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 683,
          "notificationCreateTime": "2024-09-12 08:11:22",
          "userID": 242
        },
        {
          "notificationMessage": "NAPTECH LABS LTD Requested   the  In-Content adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 684,
          "notificationCreateTime": "2024-09-12 08:11:23",
          "userID": 242
        },
        {
          "notificationMessage": "NAPTECH LABS LTD Requested   the  Under Image adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 685,
          "notificationCreateTime": "2024-09-12 08:11:26",
          "userID": 242
        },
        {
          "notificationMessage": "NAPTECH LABS LTD Requested   the  In-Image adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 686,
          "notificationCreateTime": "2024-09-12 08:18:51",
          "userID": 242
        },
        {
          "notificationMessage": "NAPTECH LABS LTD Requested   the  Standard Native Units adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 687,
          "notificationCreateTime": "2024-09-12 08:18:53",
          "userID": 242
        },
        {
          "notificationMessage": "Ananda Vikatan Digital Private Limited Requested   the  Video Stories adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 688,
          "notificationCreateTime": "2024-09-18 08:04:13",
          "userID": 242
        },
        {
          "notificationMessage": "HEHE-MOBI Requested   for the  report API access.Please take an action ",
          "isRead": false,
          "notificationID": 689,
          "notificationCreateTime": "2024-09-23 07:41:17",
          "userID": 242
        },
        {
          "notificationMessage": "ABS CBN International Requested   the  OTT apps adformats to be  implemented on there sites",
          "isRead": false,
          "notificationID": 691,
          "notificationCreateTime": "2024-10-07 11:30:22",
          "userID": 242
        }
      ],
      "companyID": 84,
      "inventoryType": "both",
      "name": "rootUser",
      "userRole": "Root",
      "userID": 242,
      "username": "amit.anand@streamlyn.com",
      "token": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhbWl0LmFuYW5kQHN0cmVhbWx5bi5jb20iLCJleHAiOjE3Mjg5MDQwMjMsImlhdCI6MTcyODgxNzYyM30.o_BO4kcdbR9Jn6Mp7K8MjVG9DhWV3GuzEjTyKpgZstuZQfB4YuIo29uiElyXyOt8csJJOqQwgzhUoLy3r8NOow"
    }
    console.log(response)
    if (JSON.stringify(response) !== '{}') {
      if (response.token) {
        debugger
        localStorage.removeItem('authUser');
        localStorage.removeItem('LoginTime');
        localStorage.setItem("darkMode", 'YES');
        // if (response.userRole !== 'Root' && response.userRole !== 'SuperAdmin') {
        //   history.push("/page-maintenance");
        //   return false
        // }else{
        //   localStorage.setItem("authUser", JSON.stringify(response));
        // }
        localStorage.setItem("authUser", JSON.stringify(response));
        yield put(loginSuccess(response));
          debugger
            let rolePriviliges2 = {
              "isContextualSettingsAccess": true,
              "isHBSettingAccess": false,
              "HouseAds": {
                "Fsrv": {
                  "report": true
                },
                "NativeAds": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "HouseAdsAdUnits": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "isFsrvAccess": true,
                "isHouseAdCreativeAccess": true,
                "isHouseAdsCategoryAccess": true,
                "isNativeAdAccess": true,
                "isHouseAdsAdUnitAccess": true,
                "HouseAdsCategories": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "HouseAdsPublishers": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "HouseAdsCreatives": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "isHouseAdsPublisherAccess": true
              },
              "adminAccess": {
                "isPublisherApprovalAccess": true,
                "isNotificationAccess": true,
                "isRoleManagementAccess": true,
                "isSupportTicketAccess": true,
                "RoleManagement": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "UserManagement": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "isUserManagementAccess": true,
                "Notification": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                }
              },
              "ReportUploading": {
                "isPublihserReportAccess": true,
                "isAgencyReportUploadAccess": true,
                "PublihserReport": {
                  "Revenue": true
                }
              },
              "isToolsAccess": true,
              "Supply": {
                "isSTDBSettingAccess": true,
                "ChildAdUnits": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "STDBSettings": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "PlayerSettings": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "isPublisherAccess": true,
                "isChildAdUnitAccess": true,
                "isPartnerAccess": true,
                "isPlayerSettingAccess": true,
                "isHighImpactSettingAccess": true,
                "partners": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "AdUnits": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "Publishers": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "AdUnitGroups": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "isAdUnitGroupAccess": true,
                "isAdUnitAccess": true,
                "HighImpactSettings": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                }
              },
              "isBillingAccess": true,
              "Dashboard": {
                "isRevenueAccess": true,
                "isAgencyAccess": true,
                "Revenue": false,
                "isAnalyticsAccess": true,
                "Analytics": false
              },
              "admin": {
                "ads.txtEntries": {
                  "read": true,
                  "create": true,
                  "update": true
                },
                "ads.txtMonitor": {
                  "read": true
                },
                "isAdsTxtManagementAccess": true,
                "isMasterLogAccess": true,
                "isPublisherNotificationAccess": true,
                "isPublisherLogAccess": true,
                "isAccessManagementAccess": true
              },
              "isAdminAccess": true,
              "isDashboardAccess": true,
              "isSwitchManagementAccess": true,
              "TagController": {
                "TCs": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                }
              },
              "SwitchAccount": {
                "isPartnerAccountAccess": true,
                "isAgencyAccountAccess": true
              },
              "PartnerManagement": {
                "PartnerAccess": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                }
              },
              "ContextualSettings": {
                "isKeywordAccess": true,
                "isPageUrlAccess": true,
                "isWebCategorizationAccess": true,
                "isInterestAccess": true,
                "isBrandAffinityAccess": true
              },
              "isNetworkSettingAccess": true,
              "isSupplyAccess": true,
              "Settings": {
                "BrandSafety": {
                  "read": true,
                  "create": true,
                  "update": true
                }
              },
              "Demand": {
                "LineItems": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "isIOsAccess": true,
                "HeaderBidding": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "isHeaderBiddingAccess": true,
                "IOs": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "TC": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "Advertisers": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "isCreativeAccess": true,
                "isLineItemAccess": true,
                "isTCAccess": true,
                "isAdveriserAccess": true,
                "Creatives": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "IOGroups": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                },
                "isIOGroupAccess": true
              },
              "ProxyAds": {
                "Ssrv": {
                  "report": true
                },
                "isSsrvAccess": true,
                "isProxyAdUnitAccess": true,
                "ProxyAdUnits": {
                  "read": true,
                  "create": true,
                  "update": true,
                  "archive": true,
                  "status": true
                }
              },
              "Report": {
                "Query": {
                  "Pageurl": true,
                  "Analytic": true,
                  "Revenue": true
                },
                "isMISAnalyticReportAccess": true,
                "isASAnalyticReportAccess": true,
                "isAnalyticReportAccess": true,
                "isPageUrlReportAccess": true,
                "isYMAnalyticReportAccess": true,
                "isTeamPageUrlReportAccess": true,
                "isAgencyReportAccess": true,
                "isRevenueReportAccess": true
              },
              "VCs": {
                "isVideoContentLibrarayAccess": false,
                "isContentMarketplaceAccess": false,
                "isVideoPlayListAccess": false,
                "isVideoImportAccess": false
              },
              "Tools": {
                "isAdsTxtValidatorAccess": true,
                "isVideoContentLibrarayAccess": true,
                "isAdTagTesterAccess": true,
                "isScreenShotToolAccess": true,
                "isVideoCompresser": true,
                "isVastTagTesterAccess": true
              },
              "isNetworkBlockingAccess": true,
              "isReportAccess": true,
              "isTagControllerAccess": true,
              "isProxyAdAccess": true,
              "isVCsAccess": false,
              "isHouseAdAccess": true,
              "isReportUploadingAccess": true,
              "Billing": {
                "isInvoiceApproveAccess": true,
                "isInvoicePaymentAccess": true,
                "Invoices": {
                  "Payment": true,
                  "History": true,
                  "Approve": true
                },
                "isInvoiceHistoryAccess": true
              },
              "isDemandAccess": true,
              "isPartnerManagementAccess": true
            }
            debugger
            localStorage.setItem("UserRolePrivileges", JSON.stringify(rolePriviliges2));
            localStorage.setItem("AdminRolePrivileges", JSON.stringify(rolePriviliges2));
            let UserRolePrivileges = rolePriviliges2
            console.log(response,UserRolePrivileges)
            debugger

            if (response.userRole === 'Partner') {
              let body = {
                url: "http://127.0.0.1:3006/v1.0/Partnertype/check",
                requestBody: {
                  userID: response.userID,
                  userRole: response.userRole,
                  companyID: response.companyID,
                  name: response.name,
                  partnerID: response.userID
                }
              }
              axios.post("/v1.0/connectPartner", body,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": `Bearer ${response.token}`
                  }
                })
                .then((res) => {
                  console.log("cdsresult is ==>", res)
                  if (res.data.success) {
                    if (res.data.partnerType === 'agency') {
                      let data = { ...response, partnerCurrencyType: res.data.partnerCurrencyType }
                      localStorage.setItem("authUser", JSON.stringify(data));
                      localStorage.setItem("UserRole", 'Agency');
                      localStorage.setItem("ParentRole", 'Agency');
                      localStorage.setItem("Terms", 'Accepted');
                      localStorage.setItem("NotificationStatus", 'open');
                      history.push("/Dashboard");
                    } else {
                      localStorage.setItem("UserRole", 'Publisher');
                      localStorage.setItem("ParentRole", 'Publisher');
                      localStorage.setItem("Terms", 'Accepted');
                      localStorage.setItem("NotificationStatus", 'open');
                      history.push("/Dashboard");
                    }
                  } else {
                    localStorage.setItem("UserRole", 'Publisher');
                    localStorage.setItem("ParentRole", 'Publisher');
                    localStorage.setItem("Terms", 'Accepted');
                    localStorage.setItem("NotificationStatus", 'open');
                    history.push("/Dashboard");
                  }

                })
                .catch((err) => {
                  console.log("e", err)
                  localStorage.setItem("UserRole", 'Publisher');
                  localStorage.setItem("ParentRole", 'Publisher');
                  localStorage.setItem("Terms", 'Accepted');
                  localStorage.setItem("NotificationStatus", 'open');
                  history.push("/Dashboard");
                });
            }
            else if (response.userRole === 'Root') {
              localStorage.setItem("UserRole", 'Admin');
              localStorage.setItem("ParentRole", 'Root');
              localStorage.setItem("NotificationStatus", 'open');
              history.push("/Dashboard");
            }
            else if (response.userRole === 'SuperAdmin') {
              localStorage.setItem("UserRole", 'Admin');
              localStorage.setItem("ParentRole", 'Root');
              localStorage.setItem("NotificationStatus", 'open');
              history.push("/Dashboard");
            }
            else {
              localStorage.setItem("UserRole", 'User');
              if (UserRolePrivileges && UserRolePrivileges.Dashboard && UserRolePrivileges.Dashboard.isRevenueAccess && UserRolePrivileges.Dashboard.isAnalyticsAccess) {
                history.push("/Dashboard");
              } else if (UserRolePrivileges && UserRolePrivileges.Dashboard && UserRolePrivileges.Dashboard.isRevenueAccess && !UserRolePrivileges.Dashboard.isAnalyticsAccess) {
                history.push("/Dashboard");
              }
              else if (UserRolePrivileges && UserRolePrivileges.Dashboard && UserRolePrivileges.Dashboard.isAnalyticsAccess && !UserRolePrivileges.Dashboard.isRevenueAccess) {
                history.push("/Dashboard");
              }
              else {
                history.push("/Dashboard");
              }
            }
          



      } else {
        yield put(apiError(response.authorization));
      }
      if (response.passwordExpired) {
        yield put(apiError('Your Password is Expired. Reset link has been sent to your registered Email'));
      }
    }
    else {
      yield put(apiError('Wrong Credentials'));
    }
  } catch (error) {
    history.push("/Dashboard");
    console.log(JSON.stringify(error))
    if (error.message === "Request failed with status code 401") {
      yield put(apiError('Wrong Credentials'));
    }
    if (error.message === "Request failed with status code 504") {
      history.push("/page-maintenance");
    }
  }
}

const loginData = (user) => {
  let item = { username: user.email, password: user.password };
  axios.post("/authenticate", item, { headers: Headers })
    .then((res) => {
      console.log("result", res);
      return res
    })
    .catch((err) => {
      console.log("e", err);
      return err
    });
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      );
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/Dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  // yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(LOGIN_USERs, loginUsers);
}

export default authSaga;
