import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    OffcanvasBody, Spinner, Label, Input, BreadcrumbItem, Modal, ModalBody
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Create_HBBuyer from "./Create_HBBuyer"

import {
    getHeaderBids as onGetHBBuyers,
    statusHeaderBid as onStatusHBBuyer,
    archiveHeaderBid as onArchiveHBBuyer,
    statusHeaderBidSuccess,
    archiveHeaderBidSuccess,
    addHeaderBidFail,
    updateHeaderBidFail,
} from "store/HeaderBidding/HeaderBid/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const HBBuyer_Management_V2 = () => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [switchLoader, setSwitchLoader] = useState(true)
    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const userRole = localStorage.getItem('UserRole');
    const [statusSearch, setStatusSearch] = useState('Active')
    const [isArchived, setIsArchived] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [selectedBuyer, setSelectedBuyer] = useState([])

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsRight(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row, isSelect, rowIndex)
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.hB_BuyerID)
                selectedItem.push(row.hB_BuyerID)
                console.log(selectedIndex)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.hB_BuyerID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.hB_BuyerID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
                console.log(selectedIndex)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.hB_BuyerID)
                    selectedItem.push(item.hB_BuyerID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }
        }
    };

    useEffect(() => {
        dispatch(onGetHBBuyers(null));
        totalRecords = 0
      }, []);
    

    const [filterState, setFilterState] = useState(false)

    const statusChange = (e) => {
        if (e.target.value === 'Active') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (statusSearch === 'Active') {
            filterCount = filterCount + 1
        }
        setStatusSearch(e.target.value)
    }

    const applyFilter = (e) => {
        dispatch(onGetHBBuyers({ userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
    }

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setStatusSearch('Active')
        filterCount = 0
        setFilterState(false)
        dispatch(onGetHBBuyers({ userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active' }));
    }

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }

    const { hbBuyers,paginationData } = useSelector(state => ({
        hbBuyers: state.headerBids.headerBids,
        paginationData: state.headerBids.pagination
    }));
  
    totalRecords = paginationData ? paginationData.total_records ? paginationData.total_records : 0 : 0

    const { loading } = useSelector(state => ({
        loading: state.headerBids.loading,
    }));

    const { hbBuyersResponse } = useSelector(state => ({
        hbBuyersResponse: state.headerBids.response,
    }));

    const { archiveHBBuyersResponse } = useSelector(state => ({
        archiveHBBuyersResponse: state.headerBids.archiveMsg,
    }));

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }

    }, [loading]);

    console.log(hbBuyersResponse)
    useEffect(() => {
        if (hbBuyersResponse !== null) {
            if (hbBuyersResponse.success) {
                toastr.success('HBBuyer Status Changed Successfully')
                setShowAlert(false)
                setSelectedBuyer([])
                dispatch(onGetHBBuyers({ userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));
                dispatch(statusHeaderBidSuccess(null));
                selectedItem = []
                selectedIndex = []
                setSelected([])
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, hbBuyersResponse]);

    useEffect(() => {
        if (archiveHBBuyersResponse !== null) {
            if (archiveHBBuyersResponse.success) {
                toastr.success('HBBuyer Archived Successfully')
                dispatch(onGetHBBuyers({ userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));
                dispatch(archiveHeaderBidSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
            }
        }
    }, [dispatch, archiveHBBuyersResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(onGetHBBuyers({ userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));
        dispatch(addHeaderBidFail(null));
        dispatch(updateHeaderBidFail(null));
        setIsRight(!isRight);
    };
    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, hbBuyer) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('HBBuyer Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('HBBuyer Updated Successfully!...')
        }
        selectedData = hbBuyer
        // if(hbBuyer.newStatus !== 'Active'){
        //     setIsArchived(true)
        // }
        // if(hbBuyer.newStatus === 'Active'){
        //     setIsArchived(false)
        // }
        setIsArchived(hbBuyer.isHB_BuyerArchived)
        dispatch(onGetHBBuyers({ userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));
        dispatch(addHeaderBidFail(null));
        dispatch(updateHeaderBidFail(null));
        setIsRight(close);
    };

    useEffect(() => {
        if (hbBuyers !== null) {
            dispatch(onGetHBBuyers({ userRole:userInfo.userRole,userID:userInfo.userID,pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch }));
        }
    }, []);

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const hbBuyerListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "hB_BuyerID",
            sort: true,
        },
        {
            dataField: "hB_BuyerCreateTime",
            text: "Date",
            sort: true,
        },
        {
            text: "Buyer Name",
            dataField: "hB_BuyerName",
            sort: true,
            formatter: (cellContent, buyer) => (
                <>
                    <span className="font-size-12 mb-1">
                        <span className=" mb-1">{buyer.hB_BuyerName}</span><br />
                        {buyer.Count !== 0 && buyer.newStatus === 'Active' &&
                            <Link to={`/AssociateAdUnit?buyerID=${buyer.hB_BuyerID}&buyerName=${buyer.hB_BuyerName}&bidderName=${buyer.hB_Bidder}&status=ViewAdUnits`}  className="color-dark-blue ">
                                Associate AdUnit
                            </Link>
                        }
                        {buyer.Count === 0 && buyer.newStatus === 'Active' &&
                            <Link to={`/AssociateAdUnit?buyerID=${buyer.hB_BuyerID}&buyerName=${buyer.hB_BuyerName}&bidderName=${buyer.hB_Bidder}&status=AssociateAdUnits`} style={{ color: 'red' }}>
                                * No AdUnits Associated | <span style={{ color: 'blue' }}> + Associate AdUnits </span>
                            </Link>
                        }
                    </span>
                </>
            ),
        },
        // {
        //     dataField: "userEmail",
        //     text: "Email",
        //     sort: true,
        //     hidden: (userInfo.userRole !== 'Root' || userRole !== 'Admin')
        // },

        {
            dataField: "hB_BuyerStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, headerBid) => (
                <>
                    <span className={headerBid.newStatus === 'Active' ? "active-state" : headerBid.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{headerBid.newStatus === 'Active' ? "Active" : headerBid.newStatus === 'Inactive' ? "Paused" : "Archive"}</span>
                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            headerAlign: 'center',
            // hidden: (UserRolePrivileges.Demand && UserRolePrivileges.Demand.HeaderBidding) && (!UserRolePrivileges.Demand.HeaderBidding.update),
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, headerBid) => (
                <span >
                    <span className="text-success " onClick={toggleDrawer(true, headerBid)}>
                        {headerBid.newStatus !== 'Active' &&
                            <i
                                className="mdi mdi-eye font-size-18"
                                id="edittooltip"
                            ></i>
                        }
                        {headerBid.newStatus === 'Active' &&
                            <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                            ></i>
                        }
                    </span> &nbsp;&nbsp;
                    {headerBid.newStatus === 'Active' && headerBid.Count !== 0 &&
                          <span className="text-danger " onClick={alertStatus(headerBid)}><i
                            className="mdi mdi-pause font-size-18"
                            id="edittooltip"
                        ></i></span>
                    }
                    {headerBid.newStatus === 'Active' && headerBid.Count === 0 &&
                       <span className="text-danger " onClick={statusHBBuyer(false, headerBid)}> <i
                            className="mdi mdi-pause font-size-18"
                            id="edittooltip"
                        ></i> </span>
                    }
                    {headerBid.newStatus === 'Inactive' &&
                         <span className="text-success " onClick={statusHBBuyer(true, headerBid)}> <i
                            className="mdi mdi-play font-size-18"
                            id="edittooltip"
                        ></i> </span>
                    }
                </span>
            ),
        },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        totalSize: hbBuyers.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archiveHBBuyer = async () => {
        dispatch(onArchiveHBBuyer({ idList: selectedItem,userRole:userInfo.userRole,userID:userInfo.userID  }));
    }

    const statusHBBuyer = (state, buyer) => (event) => {
        console.log(state)
        dispatch(onStatusHBBuyer({ id: buyer.hB_BuyerID, hB_BuyerStatus: state ,userRole:userInfo.userRole,userID:userInfo.userID }));
    }

    const alertStatus = (buyer) => (event) => {
        setShowAlert(!showAlert)
        setSelectedBuyer(buyer)
    }

    // if (hbBuyers.length > 0) {
    //     if (UserRolePrivileges.Demand && UserRolePrivileges.Demand.HeaderBidding) {
    //       if (!UserRolePrivileges.Demand.HeaderBidding.archive && !UserRolePrivileges.Demand.HeaderBidding.status) {
    //         hbBuyers.map((item, index) => {
    //           selectRow.nonSelectable.push(index + 1)
    //         })
    //       } else {
    //         hbBuyers.map((item, index) => {
    //           if (item.isHB_BuyerArchived) {
    //             selectRow.nonSelectable.push(index + 1)
    //           }
    //         })
    //       }
    //     }
    //   }

      if(hbBuyers.length > 0){
        hbBuyers.map((item, index) => {
            if (item.isHB_BuyerArchived) {
              selectRow.nonSelectable.push(index + 1)
            }
          })
      }

    console.log(hbBuyers)

    const paginationChange = async (selectedPages) => {
        console.log(selectedPages)
        pageSize = selectedPages.pageSize
        pageNumber = selectedPages.pageNumber
        dispatch(onGetHBBuyers({ userRole:userInfo.userRole,userID:userInfo.userID,pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText,filter: statusSearch }));
      }

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>HB Buyer | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    {/* Render Breadcrumbs */}

                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Home" breadcrumbItem="HB Buyer " />
                        <div className="text-sm-end">
                            <div className="btn-group">
                                <Dropdown
                                    direction="left"
                                    isOpen={isMenu}
                                    toggle={toggleMenu}
                                >
                                    <DropdownToggle
                                        tag="button"
                                        className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                    >
                                        <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                        {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                            Filter Applied
                                        </span> : <span className="d-none font-16 d-sm-inline-block">
                                            Filter
                                        </span>
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                        <div className="dropdown-item-text">
                                            <div className="row">
                                                <h5 className="mb-0">Filters</h5>
                                            </div>
                                        </div>

                                        <DropdownItem divider />
                                        <Card>
                                            <CardBody aria-hidden="true">
                                                <Row >
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label">Status</Label>
                                                        <div className=" text-sm-end">
                                                            <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                                                <option value=''> All</option>
                                                                <option value='Active' > Active</option>
                                                                <option value='Inactive'> Paused</option>
                                                                <option value='Archived'> Archived</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        <DropdownItem divider />
                                        <Row>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={clearAllFilter}
                                                >
                                                    Clear ALL
                                                </DropdownItem>
                                            </Col>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={applyFilter}
                                                >
                                                    Apply Filter
                                                </DropdownItem>
                                            </Col>
                                        </Row>

                                    </DropdownMenu>
                                </Dropdown>
                            </div> &nbsp;&nbsp;
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={toggleRightCanvas}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                New HB Buyer
                            </Button>
                        </div>

                    </div>
                      <ProductDataTableLI loading={loading} data={hbBuyers} columns={hbBuyerListColumns} statusChange={''} archiveLineItem={archiveHBBuyer} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
                </Container>
            </div>
            {/* </div> */}

            <Offcanvas isOpen={isRight} direction='end'>
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit HB Buyer' : 'New HB Buyer'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Create_HBBuyer closeCanvas={toggleDrawer(false, '')} selectedArchived={isArchived} selectedHBBuyer={selectedData} />
                </OffcanvasBody>
            </Offcanvas>

            {/* <Offcanvas isOpen={isRight} scrollable toggle={toggleRightCanvas}>
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit HBBuyer' : 'New HBBuyer'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Create_HBBuyer closeCanvas={toggleDrawer(false, '')} selectedHBBuyer={selectedData} />
                </OffcanvasBody>
            </Offcanvas> */}

            <Modal size="sm" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
                id="staticBackdrop"
            >
                <div className="modal-content">
                    <ModalBody className="px-2 py-4 text-center ">
                        <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
                        <h5 className="text-muted font-size-16 mb-4">  Are you sure, you want to Pause this Buyer? .Because already {selectedBuyer.Count} AdUnit are Associated. </h5>

                        <div className="hstack gap-2 justify-content-center mb-0">
                            <button type="button" className="btn btn-light" onClick={() => { setShowAlert(!showAlert) }}>No</button>
                            <button type="button" className="btn btn-primary" onClick={statusHBBuyer(false, selectedBuyer)}>Yes</button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>

        </React.Fragment>
    )
}

export default HBBuyer_Management_V2