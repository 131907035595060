import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getAdUnitGroup as onGetAdUnitGroups,
  statusAdUnitGroup as onStatusAdUnitGroup,
  archiveAdUnitGroup as onArchiveAdUnitGroup,
  statusAdUnitGroupSuccess,
  archiveAdUnitGroupSuccess,
  addAdUnitGroupFail,
  updateAdUnitGroupFail,
} from "store/Supply/AdUnitGroup/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_AdUnitGroup from "./Create_AdUnitGroup";
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import LineItemDataTable from "Product_Helpers/ProductDataTable"
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var adUnitGroupType = ''

var filterCount = 0
let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const AdUnitGroup = () => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [btnprimary1, setBtnprimary1] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [adUnitGroupTypeSearch, setAdUnitGroupTypeSearch] = useState('')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)

  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const UserInventoryType = localStorage.getItem('InventoryType');

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const adUnitGroupTypeChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (adUnitGroupTypeSearch === '') {
      filterCount = filterCount + 1
    }
    setAdUnitGroupTypeSearch(e.target.value)
  }

  const applyFilter = (e) => {
    if (parsed.publisherId) {
      dispatch(onGetAdUnitGroups({ publisherID: parsed.publisherId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
    } else {
      dispatch(onGetAdUnitGroups({ publisherID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  // const statusChange = (e) => {
  //   setStatusSearch(e.target.value)
  //   if (parsed.publisherId) {
  //     dispatch(onGetAdUnitGroups({ publisherID: parsed.publisherId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: e.target.value,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
  //   } else {
  //     dispatch(onGetAdUnitGroups({ publisherID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: e.target.value,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
  //   }
  // }

  // const adUnitGroupTypeChange = (e) => {
  //   setAdUnitGroupTypeSearch(e.target.value)
  //   if (parsed.publisherId) {
  //     dispatch(onGetAdUnitGroups({ publisherID: parsed.publisherId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: e.target.value }));
  //   } else {
  //     dispatch(onGetAdUnitGroups({ publisherID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: e.target.value }));
  //   }
  // }

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }


  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetAdUnitGroups(null));
    totalRecords = 0
    if (parsed.status === 'AdUnitGroupCreateByPublisher') {
      setIsRight(true);
      setCreateState(!createState)
    }
  }, []);


  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.adUnitGroupID)
        selectedItem.push(row.adUnitGroupID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.adUnitGroupID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.adUnitGroupID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.adUnitGroupID)
          selectedItem.push(item.adUnitGroupID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { adUnitGroups,paginationData } = useSelector(state => ({
    adUnitGroups: state.adUnitGroups.adUnitGroupsList,
    paginationData: state.adUnitGroups.pagination
  }));

  totalRecords = paginationData ? paginationData.total_records ? paginationData.total_records : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.adUnitGroups.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.adUnitGroups.noData,
  }));

  const { adUnitGroupsResponse } = useSelector(state => ({
    adUnitGroupsResponse: state.adUnitGroups.response,
  }));

  const { archiveAdUnitGroupsResponse } = useSelector(state => ({
    archiveAdUnitGroupsResponse: state.adUnitGroups.archiveMsg,
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.adUnitGroups.errorMsg,
  //   successMsg: state.adUnitGroups.successMsg,
  // }));

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  console.log(adUnitGroupsResponse)
  useEffect(() => {
    if (adUnitGroupsResponse !== null) {
      if (adUnitGroupsResponse.success) {
        toastr.success('AdUnitGroup Status Changed Successfully')
        if (parsed.publisherId) {
          dispatch(onGetAdUnitGroups({ publisherID: parsed.publisherId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
        } else {
          dispatch(onGetAdUnitGroups({ publisherID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusAdUnitGroupSuccess(null));
      }
    }
  }, [dispatch, adUnitGroupsResponse]);

  useEffect(() => {
    if (archiveAdUnitGroupsResponse !== null) {
      if (archiveAdUnitGroupsResponse.success) {
        toastr.success('AdUnitGroup Archived Successfully')
        if (parsed.publisherId) {
          dispatch(onGetAdUnitGroups({ publisherID: parsed.publisherId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
        } else {
          dispatch(onGetAdUnitGroups({ publisherID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(archiveAdUnitGroupSuccess(null));
      }
    }
  }, [dispatch, archiveAdUnitGroupsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    if (parsed.publisherId) {
      dispatch(onGetAdUnitGroups({ publisherID: parsed.publisherId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
    } else {
      dispatch(onGetAdUnitGroups({ publisherID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
    }
    dispatch(addAdUnitGroupFail(""));
    dispatch(updateAdUnitGroupFail(""));
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, adUnitGroup, adUnitGroupTypes) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('AdUnitGroup Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('AdUnitGroup Updated Successfully!...')
    }
    selectedData = adUnitGroup
    adUnitGroupType = adUnitGroupTypes
    setIsArchived(adUnitGroup.isAdUnitGroupArchived)
    if (parsed.publisherId) {
      dispatch(onGetAdUnitGroups({ publisherID: parsed.publisherId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
    } else {
      dispatch(onGetAdUnitGroups({ publisherID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
    }
    dispatch(addAdUnitGroupFail(""));
    dispatch(updateAdUnitGroupFail(""));
    setIsRight(close);
    setCreateState(!createState)
  };


  useEffect(() => {
    if (parsed.publisherId) {
      dispatch(onGetAdUnitGroups({ publisherID: parsed.publisherId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
    }
  }, []);

  useEffect(() => {
    if (adUnitGroups !== null && !parsed.publisherId) {
      dispatch(onGetAdUnitGroups({ publisherID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const adUnitGroupListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "adUnitGroupID",
      sort: true,
    },
    {
      dataField: "adUnitGroupCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "AdUnit Group Name",
      dataField: "adUnitGroupName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, adUnitGroup) => (
        <>
          <h5 className="font-size-12 mb-1">
          { (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 
            <Link to={`/AdUnit?adUnitGroupId=${adUnitGroup.adUnitGroupID}&adUnitGroupName=${adUnitGroup.adUnitGroupName}&adUnitType=${adUnitGroup.adUnitGroupType}&status=AdUnitCreateByParent`} className="color-dark-blue ">
              {adUnitGroup.adUnitGroupName}
            </Link> :  (UserRolePrivileges && UserRolePrivileges.Supply && UserRolePrivileges.Supply.isAdUnitAccess) ?
                 <Link to={`/AdUnit?adUnitGroupId=${adUnitGroup.adUnitGroupID}&adUnitGroupName=${adUnitGroup.adUnitGroupName}&adUnitType=${adUnitGroup.adUnitGroupType}&status=AdUnitCreateByParent`} className="color-dark-blue ">
                 {adUnitGroup.adUnitGroupName}
               </Link> : adUnitGroup.adUnitGroupName }
          </h5>
        </>
      ),
    },
    {
      text: "AdUnit Group Type",
      dataField: "adUnitGroupType",
      sort: true,
      formatter: (cellContent, adUnitGroup) => (
        <div className=' ' >
          {adUnitGroup.adUnitGroupType === 'video' &&
            <span>Video</span>
          }
          {adUnitGroup.adUnitGroupType === 'stdb' &&
            <span>Standard Banner</span>
          }
          {adUnitGroup.adUnitGroupType === 'highImpact' &&
            <span>High Impact</span>
          }
        </div>
      )
    },
    {
      dataField: "adUnitGroupStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, adUnitGroup) => (
        <>
          <span className={adUnitGroup.newStatus === 'Active' ? "active-state" : adUnitGroup.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{adUnitGroup.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.Supply && UserRolePrivileges.Supply.AdUnitGroups) && (!UserRolePrivileges.Supply.AdUnitGroups.update),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, adUnitGroup) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className="text-success " onClick={toggleDrawer(true, adUnitGroup, adUnitGroup.adUnitGroupType)}>
            {adUnitGroup.newStatus === 'Archived' &&
              <i
                className="mdi mdi-eye font-size-18"
                id="edittooltip"
              ></i>
            }
            {adUnitGroup.newStatus !== 'Archived' &&
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
              ></i>
            }
          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };


  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveAdUnitGroup = async () => {
    dispatch(onArchiveAdUnitGroup({ idList: selectedItem,userRole:userInfo.userRole,userID:userInfo.userID, }));
  }

  const statusAdUnitGroup = (state) => (event) => {
    console.log(state)
    dispatch(onStatusAdUnitGroup({ idList: selectedItem, adUnitGroupStatus: state,userRole:userInfo.userRole,userID:userInfo.userID, }));
  }

  console.log(adUnitGroups)

  if (adUnitGroups.length > 0) {
    if (UserRolePrivileges.Supply && UserRolePrivileges.Supply.AdUnitGroups) {
      if (!UserRolePrivileges.Supply.AdUnitGroups.archive && !UserRolePrivileges.Supply.AdUnitGroups.status) {
        adUnitGroups.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        adUnitGroups.map((item, index) => {
          if (item.isAdUnitGroupArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    setAdUnitGroupTypeSearch('')
    filterCount = 0
    setFilterState(false)
    if (parsed.publisherId) {
      dispatch(onGetAdUnitGroups({ publisherID: parsed.publisherId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active',userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: '' }));
    } else {
      dispatch(onGetAdUnitGroups({ publisherID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active',userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: '' }));
    }
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
    if (parsed.publisherId) {
      dispatch(onGetAdUnitGroups({ publisherID: parsed.publisherId, pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
    } else {
      dispatch(onGetAdUnitGroups({ publisherID: '', pageSize: selectedPages.pageSize, pageNumber:selectedPages.pageNumber, searchText: selectedPages.searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitGroupType: adUnitGroupTypeSearch }));
    }
  }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>AdUnit Group | Bidsxchange</title>
        </Helmet>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <div className='row'>
            <div className='col-8'>
              {parsed.publisherId ? (
                <SupplyBreadCrumb title="Supply" breadcrumbItem="AdUnit Group" publisherId={parsed.publisherId} />
              ) : (
                <SupplyBreadCrumb title="Supply" breadcrumbItem="AdUnit Group" breadList="All AdUnit Groups" />
              )}
            </div>
            <div className='col-4'>
              <div className='row'>
                  <div className="text-sm-end">
                    <div className="btn-group">
                      <Dropdown
                        direction="left"
                        isOpen={isMenu}
                        toggle={toggleMenu}
                      >
                        <DropdownToggle
                          tag="button"
                          className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                        >
                          <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                          {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                            Filter Applied
                          </span> : <span className="d-none font-16 d-sm-inline-block">
                            Filter
                          </span>
                          }
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                          <div className="dropdown-item-text">
                            <div className="row">
                              <h5 className="mb-0">Filters</h5>
                            </div>
                          </div>

                          <DropdownItem divider />
                          <Card>
                            <CardBody aria-hidden="true">
                              <Row >
                                {UserInventoryType !== 'video' &&
                                  <Col sm="12" className="mt-2">
                                    <Label className="form-label">AdUnit Group Type</Label>
                                    <div className=" text-sm-end">
                                      <Input type="select" className="form-select" value={adUnitGroupTypeSearch} onChange={adUnitGroupTypeChange}>
                                        <option value=''> All</option>
                                        <option value='stdb' >Standard Banner</option>
                                        <option value='highImpact'> High Impact</option>
                                        {UserInventoryType === 'both' &&
                                          <option value='video'> Video</option>}
                                      </Input>
                                    </div>
                                  </Col>}
                                <Col sm="12" className="mt-2">
                                  <Label className="form-label">Status</Label>
                                  <div className=" text-sm-end">
                                    <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                      <option value=''> All</option>
                                      <option value='Active' > Active</option>
                                      <option value='Inactive'> InActive</option>
                                      <option value='Archived'> Archived</option>
                                    </Input>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>

                          <DropdownItem divider />
                          <Row>
                            <Col sm="6">
                              <DropdownItem
                                className="text-primary text-center"
                                onClick={clearAllFilter}
                              >
                                Clear ALL
                              </DropdownItem>
                            </Col>
                            <Col sm="6">
                              <DropdownItem
                                className="text-primary text-center"
                                onClick={applyFilter}
                              >
                                Apply Filter
                              </DropdownItem>
                            </Col>
                          </Row>

                        </DropdownMenu>
                      </Dropdown>
                    </div> &nbsp;&nbsp;
                    {UserRolePrivileges.Supply && UserRolePrivileges.Supply.AdUnitGroups && UserRolePrivileges.Supply.AdUnitGroups.create &&
                      <div className="btn-group">
                        {UserInventoryType !== 'video' ?
                          <Dropdown
                            isOpen={btnprimary1}
                            toggle={() => setBtnprimary1(!btnprimary1)}
                          >
                            <DropdownToggle tag="button" className="btn btn-primary">
                              <i className="mdi mdi-plus-circle-outline me-1" />  Add AdUnit Group&nbsp; <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              {/* <DropdownItem header>Display</DropdownItem> */}
                              <DropdownItem onClick={toggleDrawer(true, '', 'stdb')}>Standard</DropdownItem>
                              <DropdownItem onClick={toggleDrawer(true, '', 'highImpact')}>High Impact</DropdownItem>
                              {UserInventoryType === 'both' &&
                                <DropdownItem onClick={toggleDrawer(true, '', 'video')}>Video</DropdownItem>
                              }
                            </DropdownMenu>
                          </Dropdown> : <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={toggleDrawer(true, '', 'video')}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Add AdUnit Group
                          </Button>}
                      </div>
                    }
                  </div>
              </div>
            </div>
          </div>
          <ProductDataTableLI statusAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.AdUnitGroups && UserRolePrivileges.Supply.AdUnitGroups.status) ? true : false}
            archiveAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.AdUnitGroups && UserRolePrivileges.Supply.AdUnitGroups.archive) ? true : false} loading={loading} data={adUnitGroups} columns={adUnitGroupListColumns} statusChange={statusAdUnitGroup} archiveLineItem={archiveAdUnitGroup} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize}/>
          
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit AdUnit Group' : 'New ' + adUnitGroupType + ' AdUnit Group'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_AdUnitGroup closeCanvas={toggleDrawer(false, '', '')} selectedArchived={isArchived} selectedAdUnitGroup={selectedData} selectedAdUnitGroupType={adUnitGroupType} selectedPublisher={(parsed.status === 'AdUnitGroupCreateByPublisher' || parsed.status === 'AdUnitGroupCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>


    </React.Fragment>
  )
}

export default AdUnitGroup