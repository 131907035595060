/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Row, Col, Input, Label, Form, FormFeedback,
  FormGroup,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import Select from "react-select";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import classnames from "classnames"

import {
  getAdvertisers as onGetAdvertisers,
} from "store/Demand/Advertiser/actions";

import {
  getIOGroups as onGetIOGroups,
} from "store/Demand/IOGroup/actions";

import {
  getIOs as onGetIOss,
} from "store/Demand/IOs/actions";

import {
  getLineItem as onGetLineItems,
} from "store/Demand/LineItem/actions";

import {
  getCommonSizes as onGetSizes,
} from "store/CommonEvents/actions";

// actions
import {
  readCreative as onReadCreative,
} from "store/Demand/Creative/actions";

const Creative_Selection = (props) => {
  var advertiserSelectedId = ''
  var ioGroupSelectedId = ''
  var ioSSelectedId = ''
  const dispatch = useDispatch();
  const [selectedGroup, setselectedGroup] = useState(null);

  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [creativeData, setCreativeData] = useState({
    creativeName: '',
    videoCreativeName: '',
    creativeSize: '',
    advertiserID: '',
    iOGroupID: '',
    iOID: '',
    lineItemID: '',

  });

  const [advertiserData, setAdvertiserData] = useState([])
  const [iOGroupData, setIOGroupData] = useState([])
  const [iOSData, setIOSData] = useState([])
  const [lineItemData, setLineItemData] = useState([])
  const [sizeData, setSizeData] = useState([])
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [selectedIOGroup, setSelectedIOGroup] = useState(null);
  const [selectedIOs, setSelectedIOs] = useState(null);
  const [selectedLineItem, setSelectedLineItem] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  // console.log(props)
  // console.log(props.creativeTypeName)

  useEffect(() => {
    dispatch(onReadCreative(null));
    dispatch(onGetIOGroups(null));
    dispatch(onGetIOss(null));
    dispatch(onGetLineItems(''));
  }, [])

  const { creativeInfo } = useSelector(state => ({
    creativeInfo: state.creatives.creative
  }));

  // console.log('dfsff'+creativeInfo)

  useEffect(() => {
    // console.log(props.selectedCreativeData)
    // console.log(props.creativeStatus,props.selectedCreativeData,creativeInfo)
    if (props.selectedCreativeData !== '' && props.creativeStatus) {
      dispatch(onReadCreative({ ...props.selectedCreativeData,userRole:userInfo.userRole,userID:userInfo.userID }));
    }
  }, [])

  useEffect(() => {
    if (creativeInfo !== null && props.selectedCreativeData !== '') {
      // console.log(creativeInfo)
      setCreativeData({ ...creativeData, ...creativeInfo })
      if (props.creativeType !== 'video') {
        if (creativeInfo.creativeSize) {
          setSelectedSize({ label: creativeInfo.creativeSize, value: creativeInfo.creativeSize });
          props.selectedItems({ creativeSize: creativeInfo.creativeSize })
        }
      }
      if (creativeInfo.lineItemID) {
        getParentCreative()
      }
    }
  }, [creativeInfo]);

  useEffect(() => {
    if (props.selectedLineItem !== '' && props.selectedCreativeData === '') {
      getParentCreative()
    }
  }, []);

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const { advertisers, iOGroups, iOSs, lineItems, sizes } = useSelector(state => ({
    advertisers: state.advertisers.advs,
    iOGroups: state.iOGroups.iOGroups,
    iOSs: state.iOSs.iOSs,
    lineItems: state.lineItems.lineItems,
    sizes: state.commonEvents.sizeList
  }));



  const getParentCreative = (IOGroupId, IOId, lineItemId) => {
    var filterBody = {
        "advertiserID": 0,
        "iOGroupID": 0,
        "iOID": 0,
        "lineItemID": 0,
        "creativeID": 0,
        "videoCreativeID": 0,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
    }
    // console.log(creativeInfo,props.selectedLineItem)
    if (creativeInfo) {
      if (creativeInfo.lineItemID) {
        filterBody.lineItemID = creativeInfo.lineItemID
      }
    }

    if (props.selectedLineItem) {
      filterBody.lineItemID = props.selectedLineItem.lineItemID
      props.selectedItems({ lineItemID: props.selectedLineItem.lineItemID })
    }
    if (IOGroupId) {
      filterBody.iOGroupID = IOGroupId
    }
    if (IOId) {
      filterBody.iOID = IOId
    }
    if (lineItemId) {
      filterBody.lineItemID = lineItemId
    }
    // console.log(filterBody)
    axios.post("/v2.0/demand/getParent", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data) {
          setSelectedAdvertiser({ label: res.data.advertiserName, value: res.data.advertiserID });
          setSelectedIOGroup({ label: res.data.iOGroupName, value: res.data.iOGroupID });
          if (!IOGroupId) {
            setSelectedIOs({ label: res.data.iOName, value: res.data.iOID });
            setSelectedLineItem({ label: res.data.lineItemName, value: res.data.lineItemID });
          }
          if (!IOId) {
            setSelectedLineItem({ label: res.data.lineItemName, value: res.data.lineItemID });
          }
          // setLineItem(lineItem => ({ ...lineItem, advertiserID: res.data.advertiserID, iOGroupID: res.data.iOGroupID, iOID: res.data.iOID }));
        }
      })
      .catch((err) => {
        // console.log("e", err)
      });
  }

  useEffect(() => {
    if (advertisers !== null) {
      dispatch(onGetAdvertisers({ filter: 'Active', type: props.creativeType,userRole:userInfo.userRole,userID:userInfo.userID }));
    }
  }, []);

  useEffect(() => {
    if (sizes !== null) {
      dispatch(onGetSizes());
    }
  }, []);

  useEffect(() => {
    if (iOGroups !== null && iOSs !== null && lineItems !== null) {
      dispatch(onGetIOGroups({ advertiserID: '', filter: 'Active', type: props.creativeType,userRole:userInfo.userRole,userID:userInfo.userID }));
      dispatch(onGetIOss({ iOGroupID: '', filter: 'Active', iOType: props.creativeType,userRole:userInfo.userRole,userID:userInfo.userID }));
      dispatch(onGetLineItems({ iOID: '', filter: 'Active', lineItemType: props.creativeType, priority: '',userRole:userInfo.userRole,userID:userInfo.userID }));
    }
  }, []);

  // console.log(advertisers, iOGroups, iOSs, lineItems, sizes)

  useEffect(() => {
    if (advertisers && advertisers.length > 0) {
      setAdvertiserData(objectModal(advertisers))
      // console.log(advertisers)
    }
  }, [advertisers]);

  useEffect(() => {
    if (iOGroups && iOGroups.length > 0) {
      setIOGroupData(objectIOGroupModal(iOGroups))
      // console.log(iOGroups)
    }
  }, [iOGroups]);

  useEffect(() => {
    if (iOSs && iOSs.length > 0) {
      setIOSData(objectIOsModal(iOSs))
      // console.log(iOSs)
    }
  }, [iOSs]);

  useEffect(() => {
    if (lineItems && lineItems.length > 0) {
      setLineItemData(objectLineItemModal(lineItems))
      // console.log(lineItems)
    }
  }, [lineItems]);

  useEffect(() => {
    if (sizes && sizes.length > 0) {
      if (props.creativeType === 'highImpact') {
        setSizeData([{ value: '1x1', label: '1x1' }])
        props.selectedItems({ creativeSize: '1x1' })
        setSelectedSize({ value: '1x1', label: '1x1' })
      } else {
        let sizess = objectSizesModal(sizes)
        setSizeData([...sizess, ...[{ value: '320x480', label: '320x480' }, { value: '800x485', label: '800x485' },{value:'1280x250',label:'1280x250'}, { value: '360x480', label: '360x480' },{value:'640x480',label:'640x480'}]])
      }
      // console.log(sizes)
    }
  }, [sizes]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.advertiserID
      obj.label = item.advertiserName
      dataList.push(obj)
    })
    return dataList
  }

  const objectIOGroupModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.iOGroupID
      obj.label = item.iOGroupName
      dataList.push(obj)
    })
    return dataList
  }

  const objectIOsModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.iOID
      obj.label = item.iOName
      dataList.push(obj)
    })
    return dataList
  }

  const objectLineItemModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.lineItemID
      obj.label = item.lineItemName
      dataList.push(obj)
    })
    return dataList
  }

  const objectSizesModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const handleChange = (e) => {
    // console.log(e.target)
    const { name, value } = e.target;
    setCreativeData(creativeData => ({ ...creativeData, [name]: value }));
    props.selectedItems({ [name]: value })
  }

  function handleSelectAdvertiser(selectAdvertiser) {
    setCreativeData(creativeData => ({ ...creativeData, advertiserID: selectAdvertiser.value }));
    setSelectedAdvertiser(selectAdvertiser);
    props.selectedItems({ advertiserID: selectAdvertiser.value })
    dispatch(onGetIOGroups({ advertiserID: selectAdvertiser.value, filter: 'Active', type: props.creativeType,userRole:userInfo.userRole,userID:userInfo.userID }));
    setSelectedIOGroup(null);
    setSelectedIOs(null);
    setSelectedLineItem(null);
  }

  function handleSelectIOGroup(selectIOGroup) {
    getParentCreative(selectIOGroup.value, '', '')
    setCreativeData(creativeData => ({ ...creativeData, iOGroupID: selectIOGroup.value }));
    setSelectedIOGroup(selectIOGroup);
    props.selectedItems({ iOGroupID: selectIOGroup.value })
    dispatch(onGetIOss({ iOGroupID: selectIOGroup.value, filter: 'Active', iOType: props.creativeType ,userRole:userInfo.userRole,userID:userInfo.userID}));
    setSelectedIOs(null);
    setSelectedLineItem(null);
  }

  function handleSelectIOs(selectIOs) {
    getParentCreative('', selectIOs.value, '')
    setCreativeData(creativeData => ({ ...creativeData, iOID: selectIOs.value }));
    setSelectedIOs(selectIOs);
    props.selectedItems({ iOID: selectIOs.value })
    dispatch(onGetLineItems({ iOID: selectIOs.value, filter: 'Active', lineItemType: props.creativeType, priority: '' ,userRole:userInfo.userRole,userID:userInfo.userID}));
    setSelectedLineItem(null);
  }

  function handleSelectLineItem(selectLineItem) {
    getParentCreative('', '', selectLineItem.value)
    setCreativeData(creativeData => ({ ...creativeData, lineItemID: selectLineItem.value }));
    setSelectedLineItem(selectLineItem);
    props.selectedItems({ lineItemID: selectLineItem.value })
  }

  function handleSelectSize(selectSize) {
    setCreativeData(creativeData => ({ ...creativeData, creativeSize: selectSize.value }));
    setSelectedSize(selectSize);
    // console.log(selectSize)
    props.selectedItems({ creativeSize: selectSize.value })
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  return (

    <React.Fragment>
      <div className="col-12">
        <Row form>
          <Col xs={12}>
            <div className="mb-3">
              <Label>Advertiser <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
              <Select
                value={selectedAdvertiser}
                onChange={(s) => {
                  handleSelectAdvertiser(s);
                }}
                options={advertiserData}
                classNamePrefix="select2-selection"
                isDisabled={props.selectedArchived || props.creativeStatus}
                className={(props.submitted && !creativeData.advertiserID ? ' is-invalid' : '')}
              />
              {props.submitted && !creativeData.advertiserID &&
                <div className="invalid-feedback">{'Please Select Advertiser'}</div>}
            </div>

            <div className="mb-3">
              <Label>Insertion order Group <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
              <Select
                value={selectedIOGroup}
                onChange={(s) => {
                  handleSelectIOGroup(s);
                }}
                options={iOGroupData}
                className={(props.submitted && !creativeData.iOGroupID ? ' is-invalid' : '')}
                classNamePrefix="select2-selection"
                isDisabled={props.selectedArchived || props.creativeStatus}
              />

              {props.submitted && !creativeData.iOGroupID &&
                <div className="invalid-feedback">{'Please Select IOGroup'}</div>}
            </div>
            <div className="mb-3">
              <Label>Insertion order <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
              <Select
                value={selectedIOs}
                onChange={(s) => {
                  handleSelectIOs(s);
                }}
                options={iOSData}
                classNamePrefix="select2-selection"
                className={(props.submitted && !creativeData.iOID ? ' is-invalid' : '')}
                isDisabled={props.selectedArchived || props.creativeStatus}
              />

              {props.submitted && !creativeData.iOID &&
                <div className="invalid-feedback">{'Please Select Insertion Order'}</div>}   </div>
            <div className="mb-3">
              <Label>Line Item <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
              <Select
                value={selectedLineItem}
                onChange={(s) => {
                  handleSelectLineItem(s);
                }}
                options={lineItemData}
                classNamePrefix="select2-selection"
                className={(props.submitted && !creativeData.lineItemID ? ' is-invalid' : '')}
                isDisabled={props.selectedArchived || props.creativeStatus}
              />
        
            {props.submitted && !creativeData.lineItemID &&
              <div className="invalid-feedback">{'Please Select Line Item'}</div>
            }    </div>
            {props.creativeType !== 'video' &&
              <div className="mb-3">
                <Label className="form-label">Creative Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  type="text" name="creativeName" maxLength={110} value={creativeData.creativeName} readOnly={props.selectedArchived} onKeyPress={allowAlphaNumericSpace}
                  onChange={handleChange} className={'form-control' + (props.submitted && !creativeData.creativeName ? ' is-invalid' : '')}

                />
                {props.submitted && !creativeData.creativeName &&
                  <div className="invalid-feedback">Creative Name is required</div>
                }
              </div>
            }
            {props.creativeType === 'video' &&
              <div className="mb-3">
                <Label className="form-label">Video Creative Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  type="text" name="videoCreativeName" value={creativeData.videoCreativeName} readOnly={props.selectedArchived}
                  onChange={handleChange} className={'form-control' + (props.submitted && !creativeData.videoCreativeName ? ' is-invalid' : '')}

                />
                {props.submitted && !creativeData.videoCreativeName &&
                  <div className="invalid-feedback">Video Creative Name is required</div>
                }
              </div>
            }
            {props.creativeType !== 'video' &&
              <div className="mb-3">
                <Label>Creative Size</Label>
                <Select
                  value={selectedSize}
                  onChange={s => {
                    handleSelectSize(s)
                  }}
                  options={sizeData}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
              </div>
            }
          </Col>
        </Row>

      </div>
    </React.Fragment>
  )
}

export default Creative_Selection
